<template>
    <div class="row">
        <div class="col">
            <span @click="openProgramPdf" class="home__link cursor-pointer">
                <i class="fa-solid fa-comments text-orange"></i>
                <span>Le Pré-Programme</span>
            </span>
        </div>
        <div class="col">
            <router-link to="/#acces" class="home__link">
                <i class="fa-solid fa-location-dot text-green-light"></i>
                <span>Accès</span>
            </router-link>
        </div>
        <div class="col">
            <router-link to="/inscription" class="home__link">
                <i class="fa-solid fa-file-pen text-primary"></i>
                <span>Inscription</span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        openProgramPdf() {
            window.open("/resources/PROGRAMME LADYj.Tech 3.pdf", "_blank");
        },
    },
};
</script>
