<template>
    <div class="inscription position-relative d-flex-center">
        <img src="../assets/img/bg-form.svg" class="inscription__bg" alt="" />

        <img src="../assets/img/logo-metro.svg" class="inscription__logo-metro" alt="" />

        <div class="container-fluid inscription__form mx-auto d-flex-center flex-column my-4">
            <div v-if="isLoading" class="loader d-flex-center flex-column">
                <div class="spinner-border text-green-light" role="status"></div>
                <strong class="text-green-light mt-3">Envoi du formulaire en cours...</strong>
            </div>

            <div
                v-if="hasError"
                class="w-100 alert alert-danger text-white d-flex align-items-center rounded-1"
                role="alert"
            >
                <i class="fa-solid fa-times me-3"></i>
                <div>
                    Une erreur est survenue lors de la validation de votre inscription, veuillez réessayer
                    ultérieurement.
                </div>
            </div>

            <img src="../assets/img/logo-2.png" class="inscription__logo mb-4" alt="" />

            <div class="bg-white p-3 shadow rounded" v-if="!isClosed">
                <h1 class="w-100 bg-primary text-white rounded mb-3 px-1--5 py-3">FORMULAIRE D'INSCRIPTION</h1>

                <form method="POST" class="needs-validation" novalidate>
                    <div class="row g-3">
                        <div class="col-12 d-flex-center py-3">
                            <span class="fs-5">Je souhaite participer à l'événement :</span>
                            <button
                                type="button"
                                class="btn btn-lg mx-3"
                                v-bind:class="[form_data.is_participating ? 'btn-primary' : 'btn-outline-primary']"
                                @click="participate(true)"
                                for="chk_yes"
                            >
                                Oui
                            </button>
                            <button
                                type="button"
                                class="btn btn-lg"
                                v-bind:class="[form_data.is_participating ? 'btn-outline-secondary' : 'btn-secondary']"
                                @click="participate(false)"
                                for="chk_no"
                            >
                                Non
                            </button>
                        </div>
                        <div class="col-12 col-md-4" v-if="form_data.is_participating">
                            <span class="me-5">Je souhaite assister*</span>
                        </div>
                        <div class="col-12 col-md-auto" v-if="form_data.is_participating">
                            <div class="form-check me-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="form_data.participe_forum"
                                    id="participe_forum"
                                />
                                <label class="form-check-label" for="participe_forum">À DIJ'INNOV CONNECT</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-auto" v-if="form_data.is_participating">
                            <div class="form-check me-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="form_data.participe_ladys"
                                    id="participe_ladys"
                                />
                                <label class="form-check-label" for="participe_ladys">À Lady's Industrie</label>
                            </div>
                        </div>
                        <div class="col-4"></div>
                        <div class="col-12 col-md-auto" v-if="form_data.is_participating">
                            <div class="form-check me-4">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    v-model="form_data.participe_tables"
                                    id="participe_tables"
                                />
                                <label class="form-check-label" for="participe_tables">Aux Tables Rondes</label>
                            </div>
                        </div>
                        <div class="col-12"></div>
                        <div class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control text-uppercase"
                                    type="text"
                                    v-model="form_data.last_name"
                                    id="last_name"
                                    name="last_name"
                                    placeholder="Dupond"
                                    required
                                />
                                <label for="last_name">Nom*</label>
                                <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control text-capitalize"
                                    type="text"
                                    v-model="form_data.first_name"
                                    id="first_name"
                                    name="first_name"
                                    placeholder="Alain"
                                    required
                                />
                                <label for="first_name">Prénom*</label>
                                <div class="invalid-feedback">Veuillez renseigner votre prénom</div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-floating">
                                <input
                                    class="form-control"
                                    type="email"
                                    v-model="form_data.email"
                                    id="email_address"
                                    name="email_address"
                                    placeholder="nom@exemple.fr"
                                    required
                                />
                                <label for="email_address">Adresse email*</label>
                                <div class="invalid-feedback">Adresse email invalide</div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-floating">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form_data.company_role"
                                    id="company_role"
                                    name="company_role"
                                    placeholder="SAS COMPANY"
                                    required
                                />
                                <label for="company_role">Fonction(s)*</label>
                                <div class="invalid-feedback">Veuillez renseigner vos fonctions</div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-floating">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form_data.company_name"
                                    id="company_name"
                                    name="company_name"
                                    placeholder="SAS COMPANY"
                                    required
                                />
                                <label for="company_name">Entreprise ou Organisme*</label>
                                <div class="invalid-feedback">Veuillez renseigner votre société</div>
                            </div>
                        </div>

                        <div class="col-12 ps-2" v-if="form_data.is_participating">
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    v-model="form_data.is_accompanied"
                                    type="checkbox"
                                    id="is_accompanied"
                                />
                                <label class="form-check-label" for="is_accompanied"
                                    >Je souhaite venir accompagné(e)</label
                                >
                            </div>
                        </div>

                        <div v-if="form_data.is_accompanied" class="col-12 small text-uppercase fw-bold">
                            Accompagnant 1
                        </div>
                        <div v-if="form_data.is_accompanied" class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control text-uppercase"
                                    type="text"
                                    v-model="form_data.mate1_last_name"
                                    id="mate1_last_name"
                                    name="mate1_last_name"
                                    placeholder="Dupont"
                                />
                                <label for="mate1_name">Nom</label>
                            </div>
                        </div>
                        <div v-if="form_data.is_accompanied" class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control text-capitalize"
                                    type="text"
                                    v-model="form_data.mate1_first_name"
                                    id="mate1_first_name"
                                    name="mate1_first_name"
                                    placeholder="Alain"
                                />
                                <label for="mate1_name">Prénom</label>
                            </div>
                        </div>

                        <div v-if="form_data.is_accompanied" class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control"
                                    type="email"
                                    v-model="form_data.mate1_email"
                                    id="mate1_email"
                                    name="mate1_email"
                                    placeholder="nom@exemple.fr"
                                />
                                <label for="mate1_email">Adresse email</label>
                                <div class="invalid-feedback">Adresse email invalide</div>
                            </div>
                        </div>

                        <div v-if="form_data.is_accompanied" class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="form_data.mate1_company"
                                    id="mate1_company"
                                    name="mate1_company"
                                    placeholder="SAS COMPANY"
                                />
                                <label for="mate1_name">Entreprise ou Organisme</label>
                            </div>
                        </div>

                        <div v-if="form_data.is_accompanied" class="col-12 small text-uppercase fw-bold">
                            Accompagnant 2
                        </div>
                        <div v-if="form_data.is_accompanied" class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control text-uppercase"
                                    type="text"
                                    v-model="form_data.mate2_last_name"
                                    id="mate2_last_name"
                                    name="mate2_last_name"
                                    placeholder="Dupont"
                                />
                                <label for="mate2_name">Nom</label>
                            </div>
                        </div>
                        <div v-if="form_data.is_accompanied" class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control text-capitalize"
                                    type="text"
                                    v-model="form_data.mate2_first_name"
                                    id="mate2_first_name"
                                    name="mate2_first_name"
                                    placeholder="Alain"
                                />
                                <label for="mate2_name">Prénom</label>
                            </div>
                        </div>

                        <div v-if="form_data.is_accompanied" class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control"
                                    type="email"
                                    v-model="form_data.mate2_email"
                                    id="mate2_email"
                                    name="mate2_email"
                                    placeholder="nom@exemple.fr"
                                />
                                <label for="mate2_email">Adresse email</label>
                                <div class="invalid-feedback">Adresse email invalide</div>
                            </div>
                        </div>

                        <div v-if="form_data.is_accompanied" class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="form_data.mate2_company"
                                    id="mate2_company"
                                    name="mate2_company"
                                    placeholder="SAS COMPANY"
                                />
                                <label for="mate2_name">Entreprise ou Organisme</label>
                            </div>
                        </div>
                    </div>

                    <button
                        @click="register"
                        class="w-100 btn btn-primary btn-lg mt-3"
                        type="button"
                        v-bind:disabled="
                            form_data.is_participating &&
                            !form_data.participe_forum &&
                            !form_data.participe_tables &&
                            !form_data.participe_ladys
                        "
                    >
                        Valider
                    </button>
                </form>
            </div>

            <router-link to="/" class="btn btn-primary mt-4 px-3 py-2 text-uppercase"> Retour à l'accueil </router-link>

            <div class="bg-white text-center p-3 shadow bg-opacity-50 rounded" v-if="isClosed">
                <h4 class="mb-4 text-primary" style="line-height: 2rem">
                    Nous sommes désolés, du fait de son succès, nous sommes dans l'obligation de clôturer les
                    inscriptions à cet événement.
                </h4>
                <h4 style="line-height: 2rem">
                    Si vous le souhaitez, inscrivez-vous sur la liste d'attente, en envoyant un e-mail à l'adresse
                    <u><a href="mailto:dijon@innov-events.fr">dijon@innov-events.fr</a></u>
                </h4>
            </div>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
const saveLadyjtechRegistration = httpsCallable(functions, "saveLadyjtechRegistration");
const empty_form = {
    is_participating: true,
    participe_forum: false,
    participe_tables: false,
    participe_ladys: false,
    last_name: "",
    first_name: "",
    email: "",
    company_name: "",
    company_role: "",
    is_accompanied: false,
    mate1_first_name: "",
    mate1_last_name: "",
    mate1_email: "",
    mate1_company: "",
    mate2_first_name: "",
    mate2_last_name: "",
    mate2_email: "",
    mate2_company: "",
};

export default {
    created() {
        this.cleanData();
    },
    data() {
        return {
            isClosed: false,
            isLoading: false,
            hasError: false,
            form_data: {
                is_participating: true,
                participe_forum: true,
                participe_tables: true,
                participe_ladys: true,
                last_name: "Modin",
                first_name: "Damien",
                email: "damien@innov-events.fr",
                company_name: "DMP EVENTS",
                company_role: "Directeur général",
                is_accompanied: true,
                mate1_last_name: "Pacotte",
                mate1_first_name: "Marine",
                mate1_email: "qwerty@gmail.com",
                mate1_company: "SAS DMP EVENTS",
                mate2_last_name: "Wrobel",
                mate2_first_name: "Juliette",
                mate2_email: "poipoi@gmail.com",
                mate2_company: "SAS DMP EVENTS",
            },
        };
    },
    props: {},
    methods: {
        openProgramPdf() {
            window.open("/resources/PROGRAMME LADYj.Tech 3.pdf", "_blank");
        },
        participate(is_participating) {
            this.form_data.is_participating = is_participating;
            if (!is_participating) this.form_data.is_accompanied = false;
        },
        async register() {
            if (!this.isLoading) {
                this.isLoading = true;
                const form = document.querySelector(".needs-validation");
                form.classList.add("was-validated");

                if (form.checkValidity()) {
                    await saveLadyjtechRegistration(this.form_data)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                this.cleanData();
                                router.push({ name: "done" });
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form.classList.remove("was-validated");
        },
    },
};
</script>

<style lang="scss" scoped>
$primary: #ab167e;
$secondary: #484846;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $secondary, $alpha: 0.5);
}
</style>
