<template>
    <div class="opportunites-labo inscription position-relative d-flex-center">
        <div v-if="isLoading" class="loader d-flex-center flex-column">
            <div class="spinner-border text-green-light" role="status"></div>
            <strong class="text-green-light mt-3">Chargement...</strong>
        </div>

        <img src="../assets/img/bg-form.svg" class="inscription__bg" alt="" />

        <router-link to="/">
            <img src="../assets/img/logo-metro.svg" class="inscription__logo-metro" alt="" />
        </router-link>

        <div class="container-fluid opportunites-labo__form mx-auto d-flex-center flex-column my-4">
            <div
                v-if="isFromDepot"
                class="alert alert-green-dark container-lg text-white d-flex align-items-center rounded-1"
                role="alert"
            >
                <i class="fa-solid fa-check me-3"></i>
                <div>Votre saisie à bien été enregistrée.</div>
            </div>
            <router-link to="/">
                <img src="../assets/img/logo-2.svg" class="inscription__logo mb-4" alt="" />
            </router-link>

            <h2 class="w-100 bg-green-light text-white rounded mb-3 px-1--5 py-3">LES OPPORTUNITÉS "LABORATOIRES"</h2>
            <div class="bg-white p-3 shadow rounded w-100">
                <div
                    class="accordion accordion-flush border rounded my-3 py-0--5 w-100"
                    id="accordionThemes"
                    v-if="elements?.length > 0 ?? false"
                >
                    <div class="accordion-item" v-for="(item, i) in elements" v-bind:key="i">
                        <h2 class="accordion-header" v-bind:id="`headinglabo${i}`">
                            <button
                                class="accordion-button collapsed d-flex flex-wrap align-items-baseline flex-column"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="`#labo${i}`"
                                aria-expanded="false"
                                v-bind:aria-controls="`collapselabo${i}`"
                            >
                                <span class="mb-2 text-green-light">{{ item.laboratoire }}</span>
                                <small>{{ item.thematique }}</small>
                            </button>
                        </h2>
                        <div
                            v-bind:id="`labo${i}`"
                            class="accordion-collapse collapse"
                            v-bind:aria-labelledby="`headinglabo${i}`"
                        >
                            <div class="accordion-body">
                                <div class="text-center">
                                    <a
                                        v-bind:href="`mailto:contact@sayens.fr?subject=Dij'Innov Connect - Opportunités laboratoire&body=Laboratoire : ${item.laboratoire}%0D%0A${mail_body}`"
                                        class="btn btn-green-light btn-sm"
                                    >
                                        Je souhaite en savoir plus et prendre RDV pendant l'évènement
                                    </a>
                                </div>
                                <span class="body-title">Laboratoires :</span>
                                <p>{{ item.laboratoire }}</p>

                                <span class="body-title">Thématique proposée (sujet de thèse) :</span>
                                <p>{{ item.thematique }}</p>

                                <span class="body-title">Secteur(s) industriel(s) / filière(s) visé(s) : </span>
                                <p>{{ item.secteur }}</p>

                                <p><span class="body-title">Mots-clefs :</span> {{ item.keywords }}</p>

                                <span class="body-title">Bref descriptif :</span>
                                <ul>
                                    <li>
                                        <span>Contexte :</span>
                                        <p>{{ item.context }}</p>
                                    </li>
                                    <li>
                                        <span>Objectifs scientifiques et industriels :</span>
                                        <p>{{ item.objectif }}</p>
                                    </li>
                                    <li>
                                        <span>Verrous technologiques à lever :</span>
                                        <p>{{ item.verrous }}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center" v-else>Aucune opportunité pour le moment </div>
            </div>

            <router-link to="/" class="btn btn-primary mt-4 px-3 py-2 text-uppercase"> Retour à l'accueil </router-link>
        </div>
    </div>
</template>

<script>
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
const getLadyjtechOpportuniteLabo = httpsCallable(functions, "getLadyjtechOpportuniteLabo");

export default {
    components: {},
    data() {
        let _isFromDepot = localStorage.fromDepot && JSON.parse(localStorage.fromDepot);
        localStorage.fromDepot = false;
        return {
            mail_body: `Nom :%0D%0APrénom :%0D%0AFonction :%0D%0AMail :%0D%0ATel :%0D%0AMessage :`,
            isFromDepot: _isFromDepot,
            isLoading: true,
            elements: null,
        };
    },
    mounted() {
        getLadyjtechOpportuniteLabo()
            .then((result) => {
                const data = result.data;
                this.elements = data;
            })
            .catch(function (error) {
                console.error("Une erreur est survenue", error.code, error.message, error.details);
            })
            .finally(() => (this.isLoading = false));

        // async elements() {
        //     var _elements = [];

        //     await getLadyjtechOpportuniteLabo()
        //         .then((result) => {
        //             const data = result.data;
        //             _elements = data;
        //         })
        //         .catch(function (error) {
        //             console.error("Une erreur est survenue", error.code, error.message, error.details);
        //         });
        //     return _elements;
        // },
    },
};
</script>

<style lang="scss" scoped>
$primary: #ab167e;
$secondary: #484846;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $secondary, $alpha: 0.5);
}
</style>
