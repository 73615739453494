<template>
    <div class="inscription position-relative d-flex-center">
        <img src="../assets/img/bg-form.svg" class="inscription__bg" alt="" />

        <img src="../assets/img/logo-metro.svg" class="inscription__logo-metro" alt="" />

        <div class="container-fluid inscription__form mx-auto d-flex-center flex-column my-4">
            <div v-if="isLoading" class="loader d-flex-center flex-column">
                <div class="spinner-border text-green-light" role="status"></div>
                <strong class="text-green-light mt-3">Envoi du formulaire en cours...</strong>
            </div>

            <div
                v-if="hasError"
                class="w-100 alert alert-danger text-white d-flex align-items-center rounded-1"
                role="alert"
            >
                <i class="fa-solid fa-times me-3"></i>
                <div>Une erreur est survenue lors de l'envoi du formulaire, veuillez réessayer ultérieurement.</div>
            </div>

            <img src="../assets/img/logo-2.svg" class="inscription__logo mb-4" alt="" />

            <div class="bg-white p-3 shadow rounded" v-if="!isClosed">
                <h2 class="w-100 bg-green-light text-white rounded mb-3 px-1--5 py-3">
                    FORMULAIRE D'OPPORTUNITÉ "LABORATOIRE"
                </h2>

                <form method="POST" class="needs-validation" novalidate>
                    <div class="my-4">
                        <label for="laboratoire" class="form-label text-green-light">Laboratoire :</label>
                        <input
                            type="text"
                            class="form-control"
                            id="laboratoire"
                            v-model="form_data.laboratoire"
                            placeholder=""
                        />
                    </div>
                    <div class="my-4">
                        <label for="thematique" class="form-label text-green-light">
                            Thématique proposée (sujet de thèse) :
                        </label>
                        <textarea
                            class="form-control"
                            id="thematique"
                            v-model="form_data.thematique"
                            rows="2"
                        ></textarea>
                    </div>
                    <div class="my-4">
                        <label for="secteur" class="form-label text-green-light">
                            Secteur(s) industriel(s) / filière(s) visé(s) :
                        </label>
                        <textarea class="form-control" id="secteur" v-model="form_data.secteur" rows="2"></textarea>
                    </div>
                    <div class="my-4">
                        <label for="keywords" class="form-label text-green-light">Mots-clefs :</label>
                        <textarea class="form-control" id="keywords" v-model="form_data.keywords" rows="2"></textarea>
                    </div>
                    <div class="fw-bold fs-4 text-primary">Bref descriptif</div>
                    <div class="my-4">
                        <label for="context" class="form-label text-green-light">Contexte :</label>
                        <textarea class="form-control" id="context" v-model="form_data.context" rows="10"></textarea>
                    </div>
                    <div class="my-4">
                        <label for="objectif" class="form-label text-green-light">
                            Objectifs scientifiques et industriels :
                        </label>
                        <textarea class="form-control" id="objectif" v-model="form_data.objectif" rows="10"></textarea>
                    </div>
                    <div class="my-4">
                        <label for="verrous" class="form-label text-green-light">
                            Verrous technologiques à lever :
                        </label>
                        <textarea class="form-control" id="verrous" v-model="form_data.verrous" rows="10"></textarea>
                    </div>

                    <button @click="saveData" class="w-100 btn btn-green-light btn-lg mt-3" type="button">
                        Valider
                    </button>
                </form>
            </div>

            <router-link to="/" class="btn btn-primary mt-4 px-3 py-2 text-uppercase"> Retour à l'accueil </router-link>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
const saveLadyjtechOpportuniteLabo = httpsCallable(functions, "saveLadyjtechOpportuniteLabo");
const empty_form = {
    laboratoire: "",
    thematique: "",
    secteur: "",
    keywords: "",
    context: "",
    objectif: "",
    verrous: "",
};

export default {
    props: {
        token: null,
    },
    created() {
        this.cleanData();
    },
    data() {
        return {
            isClosed: false,
            isLoading: false,
            hasError: false,
            form_data: {
                laboratoire: "Texte - Image - Langage",
                thematique:
                    "Gérer le « risque culturel » dans les coopérations internationales et les interactions numériques en équipes de projet.",
                secteur:
                    "Tous secteurs, toute organisation mettant en œuvre des projets internationaux, impliquant ou non des partenaires externes",
                keywords:
                    "projet international, interculturel, médiation identitaire, travail à distance, joint- venture, fusion-acquisition, communication numérique",
                context: "Commodo velit do id nulla veniam amet.",
                objectif: "Quis aute cupidatat commodo exercitation laboris culpa.",
                verrous: "Aliqua duis ea labore ipsum fugiat et veniam eu velit.",
            },
        };
    },
    methods: {
        async saveData() {
            if (!this.isLoading) {
                this.isLoading = true;
                const form = document.querySelector(".needs-validation");
                form.classList.add("was-validated");
                if (form.checkValidity()) {
                    var formData = this.form_data;
                    formData.token = this.token;
                    await saveLadyjtechOpportuniteLabo(formData)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                this.cleanData();
                                router.push({ name: "done-opportunite" });
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form.classList.remove("was-validated");
        },
    },
};
</script>

<style lang="scss" scoped>
$primary: #ab167e;
$secondary: #484846;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $secondary, $alpha: 0.5);
}
</style>
