/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import RegistrationView from "../views/RegistrationView.vue";
import DepotProblematiqueViewVue from "@/views/DepotProblematiqueView.vue";
import OpportunitesLaboratoiresView from "@/views/OpportunitesLaboratoiresView.vue";
import DepotOpportuniteView from "@/views/DepotOpportuniteView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import TooSoonView from "../views/TooSoonView.vue";

Vue.use(VueRouter);

const isActive = localStorage.isActive ?? false

var routes;
if (isActive || process.env.NODE_ENV == "development")
    routes = [
        {
            path: "/",
            name: "home",
            component: HomeView,
        },
        {
            path: "/inscription",
            name: "inscription",
            component: RegistrationView,
        },
        {
            path: "/opportunites-laboratoires",
            name: "opportunites-laboratoires",
            component: OpportunitesLaboratoiresView,
        },
        {
            path: "/depot-problematique",
            name: "depot-problematique",
            component: DepotProblematiqueViewVue,
        },
        {
            path: "/depot-opportunite",
            name: "depot-opportunite",
            props: route => ({ token: route.query.t }),
            component: DepotOpportuniteView,
        },
        {
            path: "/done",
            name: "done",
            redirect: (to) => {
                localStorage.fromty = true;
                return { name: "home" };
            },
        },
        {
            path: "/done-depot",
            name: "done-depot",
            redirect: (to) => {
                localStorage.fromDepot = true;
                return { name: "home" };
            },
        },
        {
            path: "/done-opportunite",
            name: "done-opportunite",
            redirect: (to) => {
                localStorage.fromDepot = true;
                return { name: "opportunites-laboratoires" };
            },
        },
        {
            path: "/404-not-found",
            name: "404",
            component: NotFoundView,
        },
        {
            path: "*",
            redirect: { name: "404" },
        },
    ];
else
    routes = [
        {
            path: "/",
            name: "home",
            component: TooSoonView,
        },
        {
            path: "/404-not-found",
            name: "404",
            component: NotFoundView,
        },
        {
            path: "/activate",
            name: "activate",
            redirect: (to) => {
                localStorage.isActive = true;
                window.location.href = '/'
                // window.location.reload()
                // return { name: "home" };
            },
        },
        {
            path: "*",
            redirect: { name: "404" },
        },];

const router = new VueRouter({
    mode: "history",
    base: __dirname,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(
                    to.hash && to.hash != "#"
                        ? {
                            selector: to.hash,
                            offset: { x: 0, y: 0 },
                            behavior: "smooth",
                        }
                        : { x: 0, y: 0 }
                );
            }, 250);
        });
    },
});

export default router;
